@import '../../../../../scss/base/colors';
@import '../../../../../scss/base/variables';

.rowTitle {
  cursor: pointer;
  font-size: $small;
  fill: $dark;

  svg:hover & {
    fill: $primary;
  }
}
