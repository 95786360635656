.login_view {
  min-width: 100%;
  height: 100vh;
  background-attachment: fixed;
  background-image: url(/assets/img/circles.svg); // fallback
  background-image: url(/assets/img/circles.svg),
    linear-gradient(
      134.72deg,
      $secondary1000 0%,
      $primary600 35.81%,
      $light400 100%
    );
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: cover;
  text-align: center;
}

.login {
  display: flex;
  -ms-flex-direction: column;
  flex-wrap: wrap;
  align-items: stretch;
  height: 100vh;
  color: $gray700;

  .login-container {
    align-self: center;
    width: 45rem;
    min-width: 45rem;
    height: auto;
    margin: 0 auto;
    margin-top: $baseline * 2;
    padding: 3rem 7rem;
    border-radius: $border-radius * 3;
    background: white;

    header {
      text-align: left;
    }

    h1 {
      margin-bottom: $baseline;
      color: $primary600;
      font-size: $base-font-size * 2;
      line-height: $h1-line-height * 0.7;
      text-align: center;
    }

    a:not(.button) {
      text-decoration: underline;
    }

    .login-header {
      > p,
      span > p {
        font-size: $base-font-size * 1.13;
        text-align: center;
      }
    }

    div {
      display: flex;
      justify-content: space-around;

      .prot-a {
        text-decoration: underline;
      }
    }

    .mfa-wrapper,
    .mfa-wrapper div,
    .mfa-wrapper-step,
    .intro {
      display: block;

      h1 {
        margin-bottom: $baseline_half;
      }

      .mfa-code {
        margin-top: $baseline * 1.5;
      }
    }

    .mfa-wrapper-step {
      min-height: 100px;

      img {
        width: auto;
        height: 158px;
        margin-bottom: 0;
      }
    }

    div.mfa-qr {
      display: flex;
      flex-direction: column;

      ul {
        margin-bottom: 0;
      }
    }

    .success--message {
      margin: $baseline * 1.5;

      input {
        margin-top: $baseline;
      }
    }
  }

  a:not(.button),
  a:active:not(.button),
  a:hover(.button),
  a:visited(.button) {
    color: $primary600;
  }

  .button {
    background: $primary600;

    &.mfa-success {
      margin-bottom: $baseline_sm;
    }
  }

  header {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    *:last-child {
      margin-bottom: 0;
    }

    margin-bottom: $baseline-half;
    text-align: center;
  }

  div {
    small {
      display: block;
      font-size: $base-font-size;
    }
  }

  textarea[disabled] {
    color: $gray500 !important; // must still be !important because we're overriding a rule with the same modifier

    + label {
      color: $gray500 !important;
    }
  }

  footer {
    display: flex;
    flex-basis: 100%;
    align-self: flex-end;
    justify-content: center;
    margin: 0 $baseline_half;

    .footer-card {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: $baseline 0;
      padding: 1rem;

      div {
        img {
          display: inline;
          width: $base-font-size * 1.8;
          margin-bottom: 0;
        }

        h3 {
          display: inline;
          margin: 0;
          padding-left: $baseline-half;
          color: $white500;
          text-align: center;
        }
      }

      &:hover {
        transition: box-shadow 0.3s ease-in-out 0s;
        box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.12);
      }

      p {
        color: $white500;
      }
    }

    p {
      margin: $baseline-half 0 0 0;
      padding: 0 $baseline-half;
    }
  }
}
