@import '../../../scss/base/variables';
@import '../../../scss/base/colors';

.flags {
  margin-left: $baseline_half;
  border-top: 1px solid $white500;
}

.flagLinkContainer a {
  display: flex;
  justify-content: space-between;

  > span:nth-of-type(2) {
    margin-left: $baseline_half;
  }
}
