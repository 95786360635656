@import '../../../../scss/base/variables';
@import '../../../../scss/base/colors';

.innerLabel {
  position: absolute;
  left: 2.4rem;
  color: $dark;
  font-weight: normal;
  font-size: $xSmall;

  .excluding & strong {
    color: $danger;
  }

  .including & strong {
    color: $dark;
  }
}

.hideExclusion :global(.rw-multiselect) {
  margin-left: $baseline !important;
}

.hideExclusionSpacing {
  :global(.rw-multiselect) {
    width: 100% !important;
    margin-left: 0 !important;
  }

  .innerLabel {
    left: 0.75rem;
  }
}
