@import '../../../../scss/base/colors';

.settings-groups {
  &.hidden {
    display: none;
  }

  &.inactive {
    .title {
      span:nth-child(1) {
        color: $white300;
        opacity: 0.5;
      }
    }

    .body {
      background-color: $white300;
    }
  }

  .header {
    padding: 15px 15px 15px 25px;
    border-bottom: 1px solid white;
    background-color: $dark600;
    color: white;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  .title {
    font-weight: 600;

    span:nth-child(1) {
      padding-right: 5px;
    }

    span:nth-child(2) {
      color: $warning;
    }
  }

  .icons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 5vw;
  }

  .arrow {
    transition: transform 200ms ease;

    &.collapsed {
      transform: rotateZ(180deg);
    }
  }

  .body {
    &.overflow {
      overflow: visible !important;
    }
  }
}
