body.activity_view {
  .subnav {
    z-index: index($z_layout, subnav);
  }
}

.activity_view {
  // Layout
  position: relative;
  height: 100vh;
  z-index: index($z_layout, app);

  .activity_view__body {
    position: relative;
    height: calc(100% - #{$subnav-height * 2});
    overflow: hidden;

    .subnav {
      width: 100%;
    }

    &.is--employee_summary,
    &.is--patient_summary {
      top: 0;
      height: 100vh;

      .activity_view__content {
        height: calc(100vh - #{$subnav-height});
      }

      .subnav {
        display: none;
      }

      .box-nav {
        display: none;
      }

      main {
        flex: 1;

        .eventLogs {
          display: none;
        }

        section {
          &.assessments {
            display: none;
          }
        }
      }
    }
  }

  .activity_view__content {
    display: flex;
    flex: 1;
    width: 100%;
    height: calc(100vh - #{$subnav-height * 2});
    min-height: 30rem;
  }

  // Charts and tables
  main {
    // init flex
    display: flex;
    min-width: 100px;

    section {
      &.dataVis {
        flex: 1;
        overflow: hidden;
        // init flex again for scrollable chart (need this for User Only Events row)
        display: flex;
        flex-direction: column;

        .user-only {
          border-bottom: 1px solid $white450;
        }
      }

      position: relative;
      transition: height 333ms ease-in-out;
    }

    .metalog-filters {
      position: relative;

      .metalog-filters-button {
        width: $baseline * 2.8;
        height: $baseline * 1.4;
        margin-right: $baseline_half;
        padding-top: 1px;
        border-radius: $border-radius * 0.7;
        background: $primary;
        color: white;
        cursor: pointer;

        i {
          width: $baseline * 1.2;
          height: $baseline * 1.4;
          padding-top: $baseline_sm * 0.6;
          padding-left: $baseline_sm * 0.5;
          color: white;
        }
      }

      .metalog-filters-dropdown {
        position: absolute;
        width: $baseline * 8.1;
        z-index: index($z_layout, activityDropdown);

        &.collapsed {
          position: absolute;
          height: 0;
        }

        ul {
          width: 100%;
          padding: $baseline_sm $baseline_half;
          list-style-type: none;
          border: 1px solid $white400;
          background: $white;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);

          li {
            cursor: pointer;

            &:last-child {
              margin-bottom: $baseline_sm;
            }

            .material-icons {
              margin-right: $baseline * 0.7;
              color: $primary;
              font-size: 1rem;

              &.icon-visibility_off,
              &.icon-visibility_off + span {
                opacity: 0.5;
              }
            }

            span {
              font-size: 0.85rem;
            }
          }
        }
      }
    }
  }

  .event-box {
    .log_controls {
      position: relative;
      float: right;
      top: 0;
      right: 0;

      .icon-call_merge {
        display: none;
      }
    }

    .loader-parent {
      height: 100%;
    }

    .pandy {
      padding: 100px 0;

      div {
        max-width: 100%;

        img {
          margin-top: $baseline * 1.5;
          margin-bottom: 0;
        }
      }

      .speechBubble {
        top: $baseline_half;
      }
    }
  }

  // hide charts when comparing user and patient
  .is--user_patient,
  .is--patient_user {
    main {
      @include hideflex;
    }
  }

  // Patient/Employee summaries - Single person view
  .is--employee_summary,
  .is--patient_summary {
    .list--button_group {
      height: 1px;
      padding: 0;
      overflow: hidden;
    }

    main {
      flex: 0 0 55%;
      flex-direction: column;
      order: 1;
    }
  }

  // Activity Views
  .is--employee_activity_view,
  .is--patient_activity_view {
    main {
      flex: 1;
      flex-direction: column;
      order: 1;

      section {
        position: relative;
        width: 100%;

        &.dataVis.fullLogs {
          height: 0;
          > .events_chart_wrapper.user-only {
            height: 0;
          }
        }
      }
    }
  }
}
