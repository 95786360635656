@import '../../../scss/base/variables';
@import '../../../scss/base/colors';

.section {
  height: 100%;
  padding: $baseline $baseline $baseline $baseline_sm;
  overflow-y: auto;
  overflow-x: hidden;

  .indent {
    padding-left: $baseline;
  }

  .dateOfBirth {
    input {
      padding: $baseline_sm !important;
    }
  }

  .icon {
    margin-top: -2px;
    margin-right: $baseline_half;
    font-size: larger;
  }

  input[type='text'] {
    margin-bottom: $baseline_half;
    padding: $input-small-form-padding;
    font-size: $small;
    font-family: $primaryFont;
    background: $white;
    border: 1px solid $white500;
    color: $dark;
    height: auto;
  }

  .button {
    margin-bottom: $baseline_half;
  }

  // Don't target the react-widget multiselect's input with some off the above
  // styles.
  :global(.rw-multiselect) input {
    margin-bottom: 0;
  }

  .bottomMargin {
    margin-bottom: $baseline_half;
  }

  // Styles to override react-widgets date picker.
  :global(.rw-input) {
    background: $white !important;
    color: $dark;
  }
}
