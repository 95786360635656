$module: 'dashboard';

.#{$module} {
  @extend %page-layout;
}

.#{$module} {
  .account__view {
    height: calc(100vh - #{$subnav-height});
    margin-top: 0;

    form {
      margin: $baseline * 1.2 $baseline;

      article {
        width: 100%;
        margin: 0;
        padding: 0;
      }

      h1 {
        margin-bottom: $baseline;
        color: $dark;
        font-size: $base-font-size * 1.5;
        line-height: $h1-line-height * 0.7;
      }
    }

    .pw-policy {
      white-space: pre-wrap;
    }

    .card_header {
      span {
        margin-right: $baseline;
        font-size: $base-font-size * 0.8;
      }
    }

    .content {
      .button[disabled] {
        cursor: not-allowed;
      }

      .mfa-wrapper {
        padding-top: 0;
        border: none;

        h1 {
          margin-bottom: 0;
          color: $dark;
          font-size: $base-font-size * 1.5;
          line-height: $h1-line-height * 0.7;
          text-align: left;
        }

        .intro {
          margin-top: $baseline;
        }

        .mfa-wrapper-step {
          display: flex;
          flex-direction: column;
          min-height: 320px;

          img {
            align-self: center;
            min-height: 175px;
          }

          .description {
            height: 75px;
          }
        }

        div.mfa-qr {
          display: flex;
          flex-direction: column;

          p.barcode {
            height: 60px;
            text-align: center;
          }

          img {
            margin-bottom: $baseline;
          }

          .verification-code {
            margin-bottom: $baseline;
          }
        }

        form {
          margin: 0;
        }
      }

      .mfa-links {
        margin: {
          right: 0;
          left: 0;
        }
      }

      form {
        span {
          ul {
            margin: $baseline 0;
          }
        }
      }
    }

    .security {
      border: none !important;

      section {
        width: 100%;
        height: auto;
        padding: 0;

        &.mfa-forced {
          width: 100%;
          height: 100%;
          padding: $baseline * 1.2 $baseline;
          background: $white;
        }

        article {
          width: 100%;
          margin: 0;
          padding: 0;

          form {
            margin: 0;

            h1 {
              text-align: left;
            }
          }
        }
      }

      ul {
        list-style-type: none;
        margin-top: $baseline;
        padding: 0;
      }
    }

    .mfa-buttons {
      display: flex;
      justify-content: space-between;
      margin-bottom: $baseline;

      input {
        border: 2px solid $primary;

        &:first-child {
          margin-right: $baseline;
        }

        &.button-back {
          background: $white;
          color: $primary;
        }
      }
    }
  }
}
