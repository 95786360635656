/* for debugging */
// *:focus {
//   outline: 1px red solid !important;
// }

.rw-sr {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.rw-widget {
  &,
  & * {
    @include box-sizing(border-box);
    text-transform: capitalize;
  }

  &:before,
  & *:before,
  &:after,
  & *:after {
    @include box-sizing(border-box);
  }
}

.rw-widget {
  outline: 0;
  -moz-background-clip: border-box;
  -webkit-background-clip: border-box;
  background-clip: border-box;
}

.rw-btn {
  color: $btn-color;
  line-height: $input-height;
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  border: 1px solid transparent;
  padding: 0;
  white-space: nowrap;
}

.rw-btn-primary {
  width: 100%;
  white-space: normal;
  line-height: 2em;
}

.rw-rtl {
  direction: rtl;
}

.rw-input {
  height: $input-height-sm;
  padding: $input-padding;
  background-color: $input-bg;
  font-size: 0.9rem;

  &[disabled] {
    @include state-disabled();
    opacity: 1;
    background-color: $input-bg-disabled;
    border-color: $input-border;
  }

  &[readonly] {
    cursor: not-allowed;
  }
}

.small-select-menu {
  .rw-input {
    height: $input-height-sm !important;
  }
}

.rw-filter-input {
  position: relative;
  width: 100%;
  padding-right: 1.9em;
  border: $input-border $input-border-width solid;
  border-radius: $input-border-radius;
  margin-bottom: 2px;

  .rw-rtl & {
    padding-left: 1.9em;
    padding-right: 0;
  }

  > .rw-input {
    width: 100%;
    border: none;
    outline: none;
  }

  > span {
    margin-top: -2px;
  }
}

.rw-widget-container {
  border: none;
  border-radius: 0;
  background-color: transparent;

  > div {
    overflow: hidden;
  }
}

.rw-now {
  font-weight: 600;
}

.rw-state-selected {
  @include state-select();
}

// &.rw-state-disabled {
.rw-state-disabled {
  @include state-disabled();
  opacity: 1;
}

.rw-btn,
.rw-dropdown-list {
  cursor: pointer;
}

.rw-btn {
  &[disabled],
  .rw-state-disabled &,
  .rw-state-readonly & {
    @include state-disabled();
    pointer-events: none;
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: 65;
  }
}

ul.rw-list {
  @include list-unstyled();
  padding: 0;
  overflow: auto;
  outline: 0;
  height: 100%;

  > li {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-transform: capitalize;
  }

  > li.rw-list-optgroup {
    font-weight: bold;
    padding-top: 10px;
    padding-left: 8px;

    &:empty {
      margin: 0;
      padding: 0;
    }
  }

  > li.rw-list-option,
  > li.rw-list-empty {
    padding-left: 10px;
    padding-right: 10px;
    padding: 6.25px;
  }

  > li.rw-list-option {
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: $border-radius-sm;
    margin-bottom: 0;
    color: $dark;

    &:hover {
      background-color: $list-bg-hover;
      border-color: $list-border-hover;
      color: $white;
    }

    &:empty {
      margin: 0;
      padding: 0;
    }

    &.rw-state-selected {
      @include state-select();
    }

    &.rw-state-disabled,
    &.rw-state-readonly {
      color: $gray-light;
      cursor: not-allowed;

      &:hover {
        background: none;
        border-color: transparent;
      }
    }
  }
}

ul.rw-list {
  &.rw-list-grouped {
    > li.rw-list-optgroup {
      padding-left: 10px;
    }

    > li.rw-list-option {
      padding-left: 20px;
      color: $dark;
    }
  }
}

.rw-widget {
  position: relative;
}

.rw-open.rw-widget,
.rw-open > .rw-multiselect-wrapper {
  @include border-bottom-radius(0);
}

.rw-open-up.rw-widget,
.rw-open-up > .rw-multiselect-wrapper {
  @include border-top-radius(0);
}

.rw-combobox,
.rw-datetimepicker,
.rw-numberpicker,
.rw-dropdown-list,
.rw-multiselect {
  & .rw-list {
    max-height: 200px;
    height: auto;
  }
}

$focus-rgba: rgba(
  red($input-border-focus),
  green($input-border-focus),
  blue($input-border-focus),
  0.6
);

.rw-widget {
  background-color: $input-bg;
  border: $input-border $input-border-width solid;
  border-radius: $input-border-radius;

  .rw-input {
    @include border-left-radius($input-border-radius);
    text-transform: capitalize;

    .rw-rtl.rw-input {
      @include border-left-radius(0);
      @include border-right-radius($input-border-radius);
    }
  }

  > .rw-select {
    border-left: $input-border 1px solid;

    .rw-rtl.rw-input {
      border-right: $input-border 1px solid;
      border-left: none;
    }
  }

  &.rw-state-focus,
  &.rw-state-focus:hover {
    @include box-shadow(
      #{'inset 0 1px 1px rgba(0,0,0,.0), 0 0 8px rgba(0,0,0,.0)'}
    );
    border-color: $input-border-focus;
    outline: 0;
  }

  &.rw-state-readonly,
  &.rw-state-readonly > .rw-multiselect-wrapper {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.rw-state-disabled {
    &,
    &:hover,
    &:active {
      @include box-shadow(none);
      background-color: $input-bg-disabled;
      border-color: $input-border;
    }
  }
}

.rw-combobox,
.rw-datetimepicker,
.rw-numberpicker,
.rw-dropdown-list {
  padding-right: 1.9em;

  &.rw-rtl {
    padding-right: 0;
    padding-left: 1.9em;
  }

  > .rw-input {
    width: 100%;
    border: none;
    outline: 0;
    @include placeholder();
  }
}

.rw-select {
  position: absolute;
  width: 1.9em;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 4;
  cursor: pointer;

  &.rw-btn,
  & > .rw-btn {
    height: 100%;
    vertical-align: middle;
    outline: 0;
  }

  .rw-rtl & {
    left: 0;
    right: auto;
  }
}

//input inset
.rw-multiselect,
.rw-combobox input.rw-input,
.rw-datetimepicker input.rw-input,
.rw-numberpicker input.rw-input {
  @include box-shadow(#{'inset 0 1px 1px rgba(0,0,0,.0)'});
}

.rw-combobox:active,
.rw-datetimepicker:active,
.rw-dropdown-list:active,
.rw-header > .rw-btn:active,
.rw-numberpicker .rw-btn.rw-state-active {
  &,
  &.rw-state-focus {
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0, 0, 0, 0));
  }
}

.rw-combobox,
.rw-datetimepicker,
.rw-numberpicker,
.rw-dropdown-list {
  &:hover {
    background-color: $state-bg-hover;
    border-color: $state-border-hover;
  }
}

.rw-dropdown-list {
  &.rw-state-disabled,
  &.rw-state-readonly {
    cursor: not-allowed;
  }

  div > .rw-input {
    line-height: $input-height-sm;
    background-color: transparent;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.rw-rtl > .rw-input {
    padding: $input-padding; // grumble resets
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
  }

  > .rw-select,
  &.rw-rtl > .rw-select {
    border-width: 0;
  }
}

.rw-numberpicker {
  .rw-btn {
    display: block;
    height: calc($input-height / 2);
    line-height: calc($input-height / 2);
    width: 100%;
    border-width: 0;
  }
}

@media print {
  .rw-select {
    display: none;
  }
}
