@import '../../../../scss/base/variables';
@import '../../../../scss/base/colors';

.column {
  padding: 1.25rem;
  flex: 1 0;
  max-width: 100%;
  overflow-y: auto;

  &:nth-child(2) {
    @media screen {
      border-left: 1px solid $white500;
    }
  }

  @media print {
    padding: 1.25rem 0 0;
  }

  h2 {
    margin-bottom: $baseline_sm * 3;
    line-height: 1rem;
  }

  h3 {
    margin-bottom: 24px;
  }
}
