%error {
  border-color: $danger;
}

form {
  ul {
    list-style: none;
    margin-bottom: $baseline;
    margin-top: 0;
    padding-left: 0;
    width: 100%;
  }

  .input-section {
    margin-bottom: $baseline * 0.5;
  }

  .input-container {
    background: $white;
    margin-bottom: $baseline * 0.5;
    position: relative;
    width: 100%;

    input,
    textarea {
      font-size: $base-font-size;
    }

    @include placeholder {
      @include placeholder-visible(false);

      color: $gray300;
    }

    // Floating Labels - inputs and textarea only
    &.js-hide-label {
      input,
      textarea {
        padding: $input-default-padding; // one place to adjust the padding
      }

      label {
        opacity: 0;
        line-height: 1;
        padding-top: 7px;
        padding-bottom: 0;
        margin-top: -10px;
        transition: margin-top 0.2s ease-in-out;
      }

      @include placeholder {
        @include placeholder-visible;
      }
    }

    &.js-error {
      border: 1px solid red;

      label {
        color: $danger;
      }
      ::placeholder {
        color: $danger;
      }
    }

    &.js-success label {
      color: $success;
    }
  }

  &.form__inline {
    ul {
      margin: 0;
      li {
        float: left;
        margin: 0 $baseline_half 0 0;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &.form__small {
    .rw-datetime-picker.rw-widget .rw-input,
    textarea,
    select {
      font-size: 90%;
      height: $baseline * 1.5;
      outline: 0;
      padding: $input-small-form-padding;
      width: 100%;
    }
  }
}

label {
  color: $primary;
  left: $baseline_half;
  font-size: 70%;
  font-weight: bold;
  opacity: 1;
  position: absolute;
  top: $baseline * 0.2;
  transition: opacity 0.4s ease-in-out, margin-top 0.2s ease-in-out;
  z-index: index($z_forms, label);

  &.disabled {
    color: $gray500 !important;
  }

  &.pendo-radio {
    position: sticky;
  }
}

label,
input,
textarea {
  display: block;
  border: 0;
}

input,
textarea {
  background: transparent;
  height: 100%;
  outline: 0;
  padding: $input-inline-label-padding;
  transition: all 100ms ease-in-out;
  width: 100%;
  position: relative;
  z-index: index($z_forms, inputs);
}

textarea {
  font-family: $primaryFont;
  resize: none;
  font-size: $small;
}

input[type='submit'] {
  appearance: none;
}

input[type='submit']:hover,
input[type='submit']:focus {
  cursor: pointer;
}

input[type='submit']:active {
  transform: scale(1);
  transition: transform 0.1s ease-in-out;
}

textarea[disabled],
textarea[readonly] {
  border-color: $white500;
  background: $white300;
  cursor: not-allowed;

  + label {
    color: $gray500 !important;
    z-index: index($z_forms, label);
  }
}

.button[disabled] {
  cursor: not-allowed;
}

textarea[readonly] {
  border: none;
  background: $white200;
  color: $gray500 !important;
}

input,
textarea {
  &.input-sm {
    padding: $input-small-padding;
  }
}

select {
  background: $white;
  border: 1px solid $white400;
  height: $baseline * 2;
  width: 100%;
}

textarea,
select {
  border: 1px solid $white500;
}

// -------------------------------------
//   Radios
// -------------------------------------

.radio {
  position: relative;
  display: block;
  margin: $baseline * 0.5 0 !important;

  input[type='radio'] {
    display: none;
  }

  input[type='radio'] + label {
    color: $dark;
    font-size: 0.9rem;
    font-weight: normal;
    left: 0;
    padding-left: $baseline * 1.5;
    position: relative;

    &:hover {
      cursor: pointer;
    }
  }

  // Radio
  input[type='radio'] + label:after {
    color: $white500;
    content: '\e836';
    font-size: 18px;
    line-height: 26px;
    cursor: pointer;
    display: block;
    font-family: 'Material Icons' !important;
    height: $baseline;
    left: 0;
    margin: 0;
    position: absolute;
    top: 0;
    text-align: center;
    vertical-align: middle;
    width: $baseline;
  }

  input[type='radio']:hover + label:after {
    color: $gray;
  }

  input[type='radio']:checked + label:after {
    content: '\e837';
    color: $primary;
    font-size: 24px;
  }

  input[type='radio'][disabled] {
    + label {
      color: $gray500;

      &:after {
        color: $gray500 !important;
      }
    }

    &:hover {
      cursor: disabled;
    }
  }

  input[type='radio'][disabled]:checked + label:after {
    color: $dark;
  }
}

// -------------------------------------
//   Form States
// -------------------------------------

.form--error {
  color: $danger;
}

.form--success {
  color: $success;
}

.login-form--error {
  color: $danger800;
}

.fadeout {
  transition: 0.7s opacity;
  opacity: 0;
}

// -------------------------------------
//   Dropdown Lists
// -------------------------------------

.rw-state-disabled {
  background: $white300 !important;
  border-color: $white500;
  color: $gray500 !important;

  .rw-input {
    color: $gray500 !important;
  }
}

.rw-state-focus {
  .rw-popup {
    .rw-state-focus {
      border: $secondary550 1px solid !important;

      &:hover {
        background: $secondary;
        color: $white;
      }
    }
  }
}

// -------------------------------------
//   Input Groups
// -------------------------------------

.input-group {
  display: flex;
  width: 100%;
  margin-bottom: $baseline * 0.5;

  li {
    margin-bottom: 0;
  }

  .input-container {
    margin-bottom: 0;
  }

  .input-group--addon {
    background-color: $white;
  }

  .input-group--addon {
    border: 1px solid $white500;
    padding: $input-group-addon-padding;
    font-size: 0.8rem;
    height: $baseline * 2.4;
    white-space: nowrap;

    &:first-child {
      border-right: 0;
    }

    &:last-child {
      border-left: 0;
    }

    &.input-group--addon__small {
      padding: $input-group-addon-small-padding;
      height: $baseline * 1.8;
    }
  }

  .input-group--addon + .input-container input {
    border-left: 0;
  }

  .input-group--addon ~ .input-container {
    input {
      border-right: 0;
    }
  }
}

.input-group-date-picker {
  input {
    padding-right: $baseline * 1.5;
  }

  .material-icons {
    position: absolute;
    right: $baseline_half;
    top: -2px;
    transform: translateY(75%);
    color: $gray400;
    font-size: $h2-font-size;
    height: 50%;
  }
}

// -------------------------------------
//   Multiselects
// -------------------------------------

.rw-multiselect-taglist {
  position: relative;
  width: 100%;
  margin-bottom: 0;
  padding: 0;
  float: left;

  li {
    position: relative;
    max-width: $dropdown-min-width * 1.9;
    height: 24px;
    margin: 5px 0 0 5px;
    float: left;
    border: 0;
    border-radius: 2px;
    background: $primary;
    color: $white;
    font-size: $xSmall;
    text-align: left !important;
    text-overflow: ellipsis;
    overflow: hidden;

    strong {
      line-height: inherit;
    }

    div {
      position: absolute;
      top: -1px;
      left: 0;
      margin-left: $baseline_half * 0.25;
      padding-right: 4px;
      font-size: $small;

      > button {
        padding-bottom: 3px;
        color: white !important;
      }
    }

    > span {
      display: inline-block;
      max-width: 95%;
      margin-left: $baseline_half;
      line-height: 24px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .rw-btn {
      line-height: 20px !important;
    }
  }
}

.inline-tags {
  .rw-multiselect-taglist {
    width: auto;
  }
}

.shortened-tags {
  .rw-multiselect-tag {
    span {
      width: 50px;
    }
  }

  .rw-multiselect-tag:hover {
    span {
      width: auto;
    }
  }
}

.rw-dropdown-list.rw-widget.rw-state-readonly {
  .rw-widget-picker.rw-widget-container.rw-widget-input {
    div {
      cursor: not-allowed;
    }
  }
}

.rw-widget-picker.rw-widget-container.rw-widget-input {
  > div {
    cursor: pointer;

    > .rw-input-reset {
      padding: $input-multiselect-padding;
      font-size: $xSmall;
      height: $baseline * 1.4;
      border: none;
      width: unset;
    }
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $dark;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: $dark;
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      color: $dark;
    }
    :-moz-placeholder {
      /* Firefox 18- */
      color: $dark;
    }
  }
}

.rw-multiselect {
  .rw-popup {
    font-size: $xSmall;
  }
}

input::-ms-clear {
  display: none;
}
