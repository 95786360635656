// -------------------------------------
//   Colors
// -------------------------------------

// White
$white: #fff;
$white200: #fcfcfd;
$white300: #f4f4f8;
$white400: #eae8f0;
$white500: #dcd9e6;

$white150: rgba($white200, 0.5);
$white250: rgba($white300, 0.5); // for shadows and hovers
$white350: rgba($white400, 0.5); // for shadows and hovers
$white450: rgba($white500, 0.5); // for shadows and hovers

// Black
$black: #000;
$black200: #030202;

// Gray
$gray: #c5c2d0;
$gray150: rgba($gray, 0.5);
$gray200: #b7b3c5;
$gray250: rgba($gray200, 0.5);
$gray300: #b0abc0;
$gray350: rgba($gray300, 0.5); // for shadows and hovers
$gray400: #a39db5;
$gray450: rgba($gray400, 0.5); // for shadows and hovers
$gray500: #8881a0; // for shadows and hovers
$gray550: rgba($gray500, 0.5); // for shadows and hovers
$gray600: #332e50; // for shadows and hovers
$gray650: rgba($gray600, 0.5);
$gray700: #403b50;
$gray750: rgba($gray700, 0.5);
$gray800: #302b3c;
$gray850: #403c50;
$gray900: rgba($gray850, 0.5); // dropdown box-shadow

// Dark - Dark Purple
$dark: #321f7d;
$dark150: rgba($dark, 0.5);
$dark200: #2d1c70;
$dark250: rgba($dark200, 0.5);
$dark300: #2b1b6b;
$dark350: rgba($dark300, 0.5); // for shadows and hovers
$dark400: #26185e;
$dark450: rgba($dark400, 0.5); // for shadows and hovers
$dark500: #20144f; // for shadows and hovers
$dark550: rgba($dark500, 0.5); // for shadows and hovers
$dark600: #492db8;
$dark650: rgba($dark600, 0.5);
$dark700: darken($dark, 11);
$dark800: darken($dark, 17);

// Light - Light Purple
$light: #886af7;
$light150: rgba($light, 0.5);
$light200: #c8bef2;
$light250: rgba($light200, 0.5);
$light300: #efeef4;
$light350: rgba($light300, 0.5);
$light400: #c86dd7;
$light450: rgba($light400, 0.5);
$light500: #b2a4f9;
$light550: rgba($light500, 0.5);

// Primary - Purple
$primary: #643ef6;
$primary150: rgba($primary, 0.5);
$primary200: #5e3beb;
$primary250: rgba($primary200, 0.5);
$primary300: #5a38e0;
$primary350: rgba($primary300, 0.5); // for shadows and hovers
$primary400: #5636d6;
$primary450: rgba($primary400, 0.5); // for shadows and hovers
$primary500: #5132c9; // for shadows and hovers
$primary550: rgba($primary500, 0.5); // for shadows and hovers
$primary600: #6447f2;
$primary700: #2f2078;

// Secondary - Blue
$secondary: #327bff;
$secondary150: rgba($secondary, 0.5);
$secondary200: #3074f2;
$secondary250: rgba($secondary200, 0.5);
$secondary300: #2f71eb;
$secondary350: rgba($secondary300, 0.5); // for shadows and hovers
$secondary400: #2d6ce0;
$secondary450: rgba($secondary400, 0.5); // for shadows and hovers
$secondary500: #2a66d4; // for shadows and hovers
$secondary550: rgba($secondary500, 0.5); // for shadows and hovers
$secondary600: #85b0ff;
$secondary650: rgba($secondary600, 0.5);
$secondary700: #abbaf4;
$secondary750: rgba($secondary700, 0.5);
$secondary800: #7e97ee;
$secondary850: rgba($secondary800, 0.5);
$secondary900: #54b2e3;
$secondary950: rgba($secondary900, 0.5);
$secondary1000: #3d7ef4;

// Success - Green
$success: #26bf5c;
$success150: rgba($success, 0.5);
$success200: #24b256;
$success250: rgba($success200, 0.5);
$success300: #23ad53;
$success350: rgba($success300, 0.5); // for shadows and hovers
$success400: #20a14d;
$success450: rgba($success400, 0.5); // for shadows and hovers
$success500: #1e9447; // for shadows and hovers
$success550: rgba($success500, 0.5); // for shadows and hovers

// Warning - Orange
$warning: #ff9c40;
$warning100: #fff8e6;
$warning150: rgba($warning, 0.5);
$warning200: #f2943d;
$warning250: rgba($warning200, 0.5);
$warning300: #eb903b;
$warning350: rgba($warning300, 0.5); // for shadows and hovers
$warning400: #e08938;
$warning450: rgba($warning400, 0.5); // for shadows and hovers
$warning500: #d48235; // for shadows and hovers
$warning550: rgba($warning500, 0.5); // for shadows and hovers

// Danger - Red
$danger: #f54949;
$danger150: rgba($danger, 0.5);
$danger200: #e84646;
$danger250: rgba($danger200, 0.5);
$danger300: #e04343;
$danger350: rgba($danger300, 0.5); // for shadows and hovers
$danger400: #d64040;
$danger450: rgba($danger400, 0.5); // for shadows and hovers
$danger500: #c93c3c; // for shadows and hovers
$danger550: rgba($danger500, 0.5); // for shadows and hovers
$danger600: #f99493;
$danger650: rgba($danger600, 0.5);
$danger700: #fbd9d9;
$danger750: rgba($danger700, 0.5);
$danger800: #ef6969;
$danger850: rgba($danger800, 0.5);

// Teal
$teal: #3eb7de;
$teal150: rgba($teal, 0.8);
$teal250: rgba($teal, 0.6);
$teal350: rgba($teal, 0.4);
$teal450: rgba($teal, 0.2);
$teal500: #69d5e4;
