$module: 'home-view';

.#{$module} {
  @extend %page-layout;

  .#{$module}__body {
    display: flex;
    position: relative;
    flex: 1;
    flex-flow: row wrap;
    align-items: stretch;
    width: 100%;
    overflow: hidden;

    //main columns
    .home-section {
      height: 100vh;
      padding: 0;
      overflow: none;
      z-index: index($z_layout, app);

      > div {
        height: calc(100vh - #{$subnav-height} - #{$nav-height});
        overflow: auto;
      }

      &.col-1 {
        position: relative;
        width: 780px;
        height: calc(100vh - 3rem);
        background: linear-gradient(rgba($teal, 0.9), rgba($primary, 0.9));
        overflow-y: auto;

        .home__cases-reporting {
          width: 330px;
          min-height: calc(100vh - #{$subnav-height} - #{$nav-height});
          float: left;
          border-right: 1px solid rgba(255, 255, 255, 0.13);

          header {
            position: fixed;
            width: 330px;
            border: 1px solid rgba(255, 255, 255, 0.13);
            background: $teal;
            z-index: index($z_layout, subnav);
          }

          > div {
            height: calc(100vh - #{$subnav-height} - #{$nav-height});
            min-height: 38rem;
            margin-top: $nav-height;
            background: linear-gradient($teal, $primary);
          }
        }

        .home__cases-open {
          width: 420px;
          float: left;
          background: $dark700;

          header {
            position: fixed;
            width: 460px;
            margin-left: -1px;
            border: 1px solid rgba($white, 0.13);
            background: $secondary900;
            z-index: index($z_layout, subnav);
          }

          > div {
            position: absolute;
            top: $nav-height;
            width: inherit;
          }
        }
      }

      &.col-2 {
        flex: 1;
        min-width: 440px;
        background: $white300;

        .home-sub-section {
          padding-bottom: 0;
        }
      }

      //column heading
      header {
        display: block;
        height: $nav-height;
        margin-bottom: 0;
        padding: $baseline $baseline;
        border-bottom: 0.5px solid $white400;

        h3 {
          float: left;
          color: $dark500;
          font-size: $h2-font-size;
          font-weight: 500;
          line-height: inherit;
          text-transform: uppercase;
        }

        .rw-dropdown-list {
          display: block;
          margin-left: $baseline_half;
          float: right;
        }
      }

      //subsections
      .home-sub-section {
        padding: $baseline $baseline;
        color: $white400;

        .button {
          &:hover {
            opacity: 1;
          }

          margin-top: -1px;
          padding-top: 1px;
          line-height: $baseline;
        }

        h2 {
          margin-right: $baseline_half;
          float: left;
          color: $white;
          font-size: 1.3rem;
          font-weight: 500;
        }

        .rw-dropdown-list.dropdown--link {
          .rw-list-option {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          .rw-popup-container {
            width: 10rem;
            margin-top: -6px;
          }
        }

        .datavis {
          clear: both;

          svg {
            display: block;
            width: 100%;
            margin: 0 auto;
            clear: both;
            border-radius: 4px;

            g:nth-of-type(1) {
              rect,
              rect:hover {
                fill: $white;
              }
            }

            g:nth-of-type(2) {
              rect {
                fill: $secondary700;
              }
            }

            g:nth-of-type(3) {
              rect {
                fill: $secondary750;
              }
            }

            g:nth-of-type(4) {
              rect {
                fill: $secondary800;
              }
            }

            g.active-selection {
              rect {
                fill: $white;
                opacity: 1;
              }
            }

            g.not-active-selection {
              rect {
                fill: $white;
                opacity: 0.3;
              }
            }
          }
        }

        //section ul - data lists
        .data {
          padding: 0;
          clear: both;
          background: none;
          list-style: none;

          span {
            float: left;
          }

          .data__count {
            float: right;
          }

          &.columns {
            clear: both;

            .column {
              display: block;
              width: 50%;
              height: auto;
              margin-bottom: 0;
              float: left;
              clear: none;
              color: $white;

              &:last-child {
                padding-left: $baseline;
              }
            }

            span {
              display: block;
              float: none;
            }

            .data__count {
              display: block;
              float: none;
              font-size: 3rem;
              font-weight: 500;
              line-height: normal;

              &.shrinkText {
                font-size: 2.8rem;
              }
            }

            &.large {
              .data__count {
                padding-bottom: $baseline_half;
                font-size: 3.7rem;
              }
            }
          }

          .not-active-selection {
            opacity: 0.5;
            pointer-events: none;
          }

          .active-selection {
            opacity: 1;
            i {
              color: $white !important;
            }
          }
        }

        .data__breakdown {
          > li.caseAge-list-selection {
            clear: both;
            color: $white;

            &:nth-child(1) i {
              color: $white;
            }
            &:nth-child(2) i {
              color: $secondary700;
            }
            &:nth-child(3) i {
              color: $secondary750;
            }
            &:nth-child(4) i {
              color: $secondary800;
            }

            &:last-child {
              margin-bottom: 0;
            }

            &:hover {
              cursor: pointer;
            }
          }

          i {
            margin-right: $baseline_half;
            float: left;
            font-size: 1.5rem;
            line-height: 1.7rem;
          }
        }

        &.report-totals {
          clear: both;

          .rw-dropdown-list > .rw-input {
            max-width: 9.25rem;
          }

          .columns.large.data {
            .data__count {
              font-size: 4rem;
            }
          }
        }

        &.report-ages {
          clear: both;
        }
      }

      //activity feed
      .home-sub-section .feed {
        margin-bottom: 0;
        padding: 0;
        line-height: 1.3rem;
        list-style: none;

        li {
          margin: 0 0 $baseline;
          background: $white;

          &.fadeIn {
            .case-activity-title {
              h5 {
                display: inline;
                opacity: 1;
              }

              .label {
                float: right;
              }

              p {
                margin-top: $baseline_half;
              }
            }
          }

          > div {
            padding: $baseline_half;
            border-top: 1px solid $white300;
            color: $dark;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            &.activity_summary {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            }

            p {
              width: 100%;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            > div {
              padding: calc($baseline_sm / 2) 0;
              font-size: $small;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }

            .time-stamp {
              padding-left: calc($baseline_half / 2);
              float: right;
              color: $gray300;
            }

            i {
              position: relative;
              line-height: inherit;
              margin-right: $baseline_half;
              float: left;
              font-size: inherit;
            }

            strong {
              line-height: inherit;
            }

            &.comment {
              background: $white250;
            }
          }
        }
      }

      .home-sub-section.results-empty {
        position: relative;
        height: 50vh;
        overflow: hidden;

        .pandy div .speechBubble span:first-of-type {
          font-size: 0.9rem;
        }
      }

      //dark column
      &.dark {
        span.data__count {
          color: $white;
        }

        header {
          border-bottom: 1px solid rgba($white, 0.13);

          h3 {
            color: $white;
          }
        }
      }

      //dropdown text links
      a {
        &.dropdown-link {
          margin-left: $baseline_half;
          color: $white;
          text-decoration: underline;
        }

        i {
          font-size: 1.2rem;
        }
      }

      &.home__cases-reporting header .rw-dropdown-list {
        width: 55%;
      }

      &.home__recent-activity header .rw-dropdown-list {
        width: 28%;
      }
    }

    .home__cases-new {
      .split-two {
        display: inline-block;
        width: 49%;
        padding-top: $baseline_sm;

        input {
          border: 1px solid rgba($white, 0.2);
        }

        &.end-date {
          margin-left: 2%;
        }

        .rw-i.rw-i-calendar {
          color: $white !important;
        }

        label {
          left: inherit;
          padding-left: $baseline_half;
          color: $white;
        }
      }

      .currentDates {
        margin-left: $baseline_half;
        color: rgba($white, 0.8);
        font-size: $xSmall;

        > i {
          margin-right: $baseline_sm;
          font-size: $small;
          vertical-align: text-top;
        }
      }

      > span {
        display: inline-block;
        margin-top: $baseline;
      }

      svg {
        display: block;
        margin: 0 auto;
      }
    }

    .home__cases-open {
      .data.columns {
        .column {
          & + .column {
            border-left: 1px solid rgba($white, 0.3);
          }
        }
      }
    }

    .home__cases-closed {
      .dropdown--link {
        max-width: 8.6rem;
        text-overflow: ellipsis;
      }

      h2 {
        margin-bottom: 0;
      }
    }
  }
}

.case-ages__chart-segment {
  cursor: pointer;
}
