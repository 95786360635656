$module: 'statements_view';

.#{$module} {
  @extend %page-layout;
}

@media screen {
  .#{$module} {
    .#{$module}__body {
      position: relative;
      width: 100%;
      overflow: hidden;
      font-size: $base-font-size * 1.05;

      h4 {
        margin-left: 0;
        margin-bottom: $baseline_half;
      }

      .loader-parent {
        width: 100%;
        > div {
          height: calc(100vh - #{$subnav-height});
          display: flex;
        }
      }

      .#{$module}__multiselect_container {
        margin-top: $baseline_half;
      }

      section {
        position: relative;
        width: 70%;
        height: 100%;
        clear: both;
        background: $white250;
        overflow-x: hidden;

        #by_the_numbers__wrapper {
          position: relative;
          width: auto;
          margin: $nav-height $baseline $baseline;
          padding-bottom: $baseline_half;
          border-radius: $border-radius;
          background: $white;
          box-shadow: 0 0 24px 0 rgba($gray700, 0.2);
          overflow: auto;
          z-index: 0;

          .by_the_numbers-header {
            margin: $baseline;
            color: $gray700;

            h1 {
              font-weight: bold;
              margin-bottom: 0;
            }
          }

          .by_the_numbers-sub-header {
            margin-top: 0;
            margin-bottom: 0;
          }

          > .numbers {
            border-top: 1px solid rgba($gray700, 0.2);
          }

          ul {
            list-style-type: none;

            li {
              margin-top: $baseline_half;
              margin-right: $baseline * 0.75;
              color: $gray700;

              span {
                float: right;
              }

              p {
                font-size: 18px;
              }
            }
          }

          .stats {
            li {
              margin: 0 $baseline;
              padding: $baseline_half 0;
              border-top: 1px solid rgba($gray700, 0.2);

              i {
                margin-right: $baseline_half;
                color: $secondary1000;
                font-size: $base-font-size * 1.05;
              }

              .title {
                display: inline-block;

                .title--na {
                  padding-right: $baseline_sm;
                  color: $secondary1000;
                  font-size: $base-font-size;
                }
              }

              .stat {
                width: $baseline * 5.5;
                float: right;
                color: $secondary1000;
              }

              &:last-child {
                margin-bottom: $baseline-half;
                border-bottom: 1px solid rgba($gray700, 0.2);
              }

              .empty-space {
                margin-left: $baseline * 1.15;
              }
            }
          }
        }

        #results__wrapper {
          position: relative;
          margin: {
            right: $baseline;
            bottom: $baseline;
            left: $baseline;
          }
          padding-bottom: $baseline;
          border-radius: $border-radius;
          background: $white;
          box-shadow: 0 0 24px 0 rgba($gray700, 0.2);
          z-index: 0;

          .results__wrapper-header {
            margin: $baseline;
            padding-top: $baseline;
            color: $gray700;
            font-weight: bold;

            h1 {
              margin-bottom: 0;
            }
          }

          .chart__container {
            position: relative;
            margin: $baseline_half;
            padding-bottom: $baseline;
            border: 1px solid rgba($gray700, 0.2);

            .chart__title {
              h2,
              p {
                margin-bottom: 0 !important;
                color: $gray700;
                text-align: center;
              }

              h2 {
                padding-top: $baseline_half;
                font-weight: bold;
              }

              .chart__title-sub {
                top: -2px;
                position: relative;
                color: $gray700;
                text-align: center;
              }

              p {
                margin-top: -8px;
                font-size: $base-font-size * 0.9;
              }

              .prot-a {
                position: absolute;
                top: 0;
                right: 0;
              }

              i {
                position: absolute;
                top: $baseline * 0.75;
                right: $baseline * 0.75;
                color: $primary;
              }
            }

            .disclaimer {
              position: relative;
              color: black;
              top: $baseline_half;
              left: $baseline_half;
              font-size: $base-font-size * 0.7;
            }

            > .chart-empty {
              height: 340px;
              text-align: center;

              .VictoryContainer {
                display: none;
              }

              &:before {
                display: block;
                padding-top: 150px;
                content: 'No data found in the time range for this chart';
              }
            }
          }
        }

        .subnav.actionbar {
          top: 0;
          color: $primary;

          li {
            position: absolute;
            right: 0;

            i {
              margin-right: $baseline_half * 1.5;
            }
          }
        }
      }

      aside {
        position: relative;
        width: 30%;
        height: 100%;
        padding: $baseline_half 0 $baseline_half $baseline;
        background-color: $white350;
        overflow-y: auto;

        h1 {
          margin-bottom: $baseline_sm;
          color: $gray700;
        }

        .#{$module}__timeframe {
          width: $baseline * 10;
          background-color: $white350;
        }

        > ul {
          list-style-type: none;
          padding-top: $baseline;
          padding-left: 0;

          li {
            color: $primary;

            ul {
              list-style-type: none;

              .category-li {
                display: inline;
              }

              .icon-keyboard_arrow_right,
              .icon-keyboard_arrow_down,
              .icon-close {
                cursor: pointer;
              }

              i {
                position: absolute;
                left: $baseline;
              }

              .category-count {
                margin-left: $baseline_half;
                padding: $baseline_sm 8px;
                border-radius: $border-radius * 2.5;
                background-color: $primary;
                color: $white;
              }

              .categories_list {
                position: relative;

                input {
                  width: 95%;
                  height: $subnav-height * 0.6;
                  margin: $baseline_half 0;
                  padding: 0 $baseline * 1.5 0 $baseline * 1.5;
                  border-radius: $border-radius * 12;
                  background-color: $white;
                  font-size: $base-font-size;
                }

                .material-icons {
                  position: absolute;
                  top: $subnav-height * 0.15;
                  font-size: $base-font-size * 1.2;
                  z-index: index($z_forms, label);
                }

                .icon-filter_list {
                  left: $baseline_half;
                }

                .icon-close {
                  right: $baseline;
                  left: inherit;
                  margin-right: 0.6rem;
                }
              }
            }
          }

          h2 {
            display: inline-block;
            margin-bottom: 0;
            cursor: pointer;
          }
        }
      }
    }
  }
  .reports_view .statements_view__body section #by_the_numbers__wrapper {
    margin-bottom: calc(#{$baseline} + #{$subnav-height});
  }
}

@media print {
  .statements_view {
    width: 860px;
    min-width: auto !important;
    background: none;
    color: $black;

    aside {
      display: none;
      width: 0;
    }
  }

  .subnav.actionbar {
    display: none !important;
  }

  .results__wrapper-header h1 {
    margin-bottom: 0;
  }

  #by_the_numbers__wrapper {
    .by_the_numbers-header h1 {
      margin-bottom: 0;
    }

    > h1,
    .numbers {
      display: inline;

      ul {
        display: inline;

        li {
          display: inline;

          p {
            display: inline;
          }
        }
      }
    }

    .stats,
    .icon-note_add {
      display: none;
    }
  }

  .print-title {
    margin-top: $baseline !important;
    padding-bottom: $baseline * 1.5;
    clear: both;
  }

  .byTheNumbers {
    table {
      border-collapse: collapse;

      tr {
        border-bottom: 1px solid $gray150;
      }

      tr:last-child {
        border: none;
      }
    }

    .footer {
      margin-top: $baseline * 1.5;

      p {
        margin-bottom: 0;
      }
    }
  }

  .chart__container {
    margin-bottom: $baseline * 2;
    page-break-inside: avoid;
    page-break-after: always;

    .chart__title {
      text-align: center;

      h2 {
        display: inline;
      }

      .times {
        display: inline;
        padding-left: $baseline;
      }

      i {
        display: none;
      }
    }

    .chart-empty {
      text-align: center;

      .VictoryContainer {
        display: none;
      }
    }

    .chart-empty:before {
      display: block;
      padding: 100px 0;
      content: 'No data found in the time range';
    }
  }

  textarea {
    display: none;
  }
}

// Hack to fix an IE issue. This allows text to have the correct size on the
// Resolution Time chart.
#ResolutionTime
  .VictoryContainer
  svg:not([aria-labelledby^='victory-container']) {
  height: 525px !important;
}
