@import '../../../../scss/base/colors';
@import '../../../../scss/base/variables';

.vipUploads {
  display: flex;
  flex: 1;
  justify-content: center;
  padding-top: $baseline * 3;

  h2 {
    font-size: $h2-font-size;
  }

  li a {
    text-decoration: underline;
  }

  ul {
    margin-bottom: $baseline;
  }

  ul li {
    margin-bottom: $baseline_sm;
    font-size: $small;
  }

  ol li {
    button {
      padding-left: 0;
      background-color: transparent;
      color: $primary;
      font-size: $base-font-size;
      font-weight: initial;
      text-decoration: underline;
      text-transform: none;
      cursor: pointer;
    }
  }

  .buttonDiv {
    margin-top: $baseline * 1.25;
    margin-bottom: $baseline;

    form {
      display: inline;
    }

    button {
      margin-right: $baseline * 1.25;
      color: $primary200;
      background: white;
    }

    button:first-child {
      color: white;
      background: $primary200;
    }
  }

  .note {
    color: $gray500;
  }

  .uploadInfo {
    max-width: 45rem;
    margin-top: $baseline * 1.25;

    .fileName {
      margin: 0 $baseline_half $baseline_half 0;
      padding: $baseline_sm $base-font-size;
      border: 1px solid transparent;
      border-radius: 2px;
      background: white;
    }

    .icon {
      padding-right: $baseline_half;
      color: $danger;
    }

    .warning {
      padding-top: $baseline_half;
      color: $danger;
    }
  }
}
