.pandy {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: $white300;

  &.helloPandy {
    display: flex;
    animation: faderIn 300ms ease;
  }

  div {
    position: relative;
    width: 100%;
    max-width: 57%;

    img {
      position: relative;
      max-width: 275px; // the Pandy image isn't square, it's rectangular, and this caps it at an appropriate aspect ratio
      max-height: 180px;
    }
  }

  //bubble container
  .speechBubble {
    position: absolute;
    top: -$baseline;
    width: 100%;
    max-width: 100%;
    text-align: center;
    opacity: 0;
    animation: pandyBubble 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 333ms;
    animation-fill-mode: both;

    span {
      display: inline-block;
      position: relative;
      padding: $baseline_half $baseline;
      border: 3px solid $gray500;
      border-radius: 40px;
      background: $white;
      color: $gray500;
      font-weight: bold;
      text-align: center;
      z-index: index($z_pandy, speechtext);

      &:after,
      &:before {
        position: absolute;
        top: 100%;
        left: 50%;
        width: 0;
        height: 0;
        border: solid transparent;
        content: ' ';
        pointer-events: none;
      }

      &:after {
        top: calc(100% - 1px);
        margin-left: -9px;
        border-width: 9px;
        border-top-color: $white;
      }

      &:before {
        margin-left: -12px;
        border-width: 12px;
        border-top-color: $gray500;
      }
    }
  }

  img {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
}

.minHeightWrapper > .helloPandy {
  min-height: 30rem;
}

.tinyPandy {
  animation: faderIn 300ms ease;
  margin-top: auto;
  padding-top: $baseline;

  > div {
    display: grid;
    display: -ms-grid;
    justify-content: center;
    height: 4rem;
    grid-template-columns: 1fr 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-gap: $baseline_sm;

    img {
      -ms-grid-column: 1;
      justify-self: self-end;
      -ms-grid-column-align: end;
      width: 4rem;
      height: 4rem;
      margin-bottom: 0;
    }
  }

  //bubble container
  .speechBubble {
    justify-self: baseline;
    -ms-grid-column: 2;
    -ms-grid-column-align: start;
    animation: pandyBubble 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 333ms;
    animation-fill-mode: both;

    span {
      position: relative;
      padding: $baseline_sm $baseline_half;
      border-radius: 10px;
      background: $gray500;
      color: $white;
      font-size: 14px;
      z-index: index($z_pandy, speechtext);

      &:before {
        position: absolute;
        top: 69%;
        left: -3%;
        transform: rotate(55deg);
        content: ' ';
      }

      &:before {
        border-top: 17px solid $gray500;
        border-right: 5px solid transparent;
        border-bottom: transparent;
        border-left: 5px solid transparent;
      }
    }
  }
}

.outlinePandy {
  margin-top: $baseline_sm;
  animation: faderIn 300ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  border: 1px solid #d3d0e2;
  border-radius: 8px;

  .noInfo {
    text-align: center;
    color: #d3d0e2;
    display: flex;
    margin: 0;
  }

  img {
    width: 4rem;
    height: 4rem;
    margin-bottom: 0;
  }
}

@media print {
  .pandy {
    display: none !important;
  }
}
