$gray-base: #000;
$gray-darker: lighten($gray-base, 13.5%); // #222
$gray-dark: lighten($gray-base, 20%); // #333

$gray: lighten($gray-base, 33.5%); // #555
$gray-light: lighten($gray-base, 46.7%); // #777
$gray-lighter: lighten($gray-base, 93.5%); // #eee

$light-gray: #ccc;
$lighter-gray: lightness($light-gray);

$white: #fff;
$white-dark: #dcd9e6;

$primary: #643ef6;
$secondary: #327bff;
$dark: #321f7d;

$text-color: $gray-base;

$border-radius: 0px;
$border-radius-sm: 0px;

// button
$btn-bg: $white;
$btn-color: $text-color;
$btn-border: #ccc;

$state-bg-select: $secondary;
$state-border-select: $secondary;
$state-color-select: $white;

$state-bg-hover: darken($btn-bg, 1%);
$state-border-hover: darken($btn-border, 5%);
$state-color-hover: white;

$widget-bg: #fff;
$widget-border: $gray;
$line-height: 1.5625;

$state-bg-focus: $widget-bg;
$state-border-focus: $white-dark;
$state-color-focus: $gray-base;

$font-size: 1em;
$font-family: inherit;

$widget-bg: #fff;
$widget-border: #ccc;

$btn-bg-hover: $state-bg-hover;
$btn-border-hover: $state-border-hover;
$btn-active-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);

$select-btn-bg: $btn-bg;
$select-btn-color: $btn-color;
$select-btn-border: $btn-border;

$select-btn-bg-hover: $btn-bg-hover;
$select-btn-border-hover: $btn-border-hover;
$select-btn-active-box-shadow: $btn-active-box-shadow;

//
// Picker inputs
//
$input-height: 2.429em; // 2.5em is good for 16px roots
$input-padding-horizontal: 0.857em;
$input-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
$input-height-sm: 2.125rem;
$input-padding: 0.781rem;

$input-bg: $widget-bg;
$input-color-placeholder: $dark;

$input-border: $white-dark;
$input-border-width: 1px;
$input-border-radius: $border-radius;

$input-bg-disabled: $gray-lighter;
$input-border-disabled: $input-border;

$input-bg-hover: $gray-lighter;
$input-border-hover: $input-border;

$input-bg-focus: transparent;
$input-border-focus: $state-border-focus;

$input-focus-color: rgba(
  red($input-border-focus),
  green($input-border-focus),
  blue($input-border-focus),
  0.6
);
$input-focus-box-shadow: 0 0 8px $input-focus-color;

//
// List
//
$list-font-size: $font-size;

$list-bg-hover: #327bff;
$list-border-hover: transparent;

$list-bg-focus: $state-bg-focus;
$list-border-focus: $state-border-focus;
$list-color-focus: $state-color-focus;

$list-bg-select: $state-bg-select;
$list-border-select: $state-border-select;
$list-color-select: $state-color-select;

$list-text-color: $text-color;
$list-padding-vertical: 0.143em;
$list-padding-horizontal: 0.75em;

//
// Popup
//
$popup-bg: $widget-bg;
$popup-border: $widget-border;
$popup-box-shadow-offset: 6px;
$popup-box-shadow: 0 ($popup-box-shadow-offset - 1px) $popup-box-shadow-offset
  rgba(0, 0, 0, 0.2);
$popup-box-shadow-up: 0 -2px $popup-box-shadow-offset rgba(0, 0, 0, 0.2);

$popup-zindex: 6;
$popup-zindex-focused: $popup-zindex + 1;

//
// Calendar
//
$calendar-bg: $widget-bg;
$calendar-cell-align: center;
$calendar-cell-padding: 0.25em;
$calendar-cell-color: $btn-color;
$calendar-cell-border-radius: $input-border-radius;

$calendar-cell-bg-hover: $state-bg-hover;
$calendar-cell-border-hover: $state-border-hover;
$calendar-cell-color-hover: $state-color-hover;

$calendar-cell-bg-focus: $state-bg-focus;
$calendar-cell-border-focus: $state-border-focus;
$calendar-cell-color-focus: $state-color-focus;

$calendar-cell-bg-select: $state-bg-select;
$calendar-cell-border-select: $state-border-select;
$calendar-cell-color-select: $state-color-select;

//
// Multiselect
//
$multiselect-gutter-pt: 0.115;
$multiselect-tag-padding-left: 0.35em;
$multiselect-tag-padding-right: 0.35em;
$multiselect-tag-color: inherit;
$multiselect-tag-border: $btn-border;
$multiselect-tag-bg: $gray-lighter;
$multiselect-tag-height-pt: 1 - ($multiselect-gutter-pt * 2);
$multiselect-tag-gutter-fallback: $input-height * $multiselect-gutter-pt;
$multiselect-tag-gutter: #{'calc(${multiselect-tag-gutter-fallback} - ${input-border-width})'};
$multiselect-tag-height: $input-height * $multiselect-tag-height-pt;
$multiselect-tag-border-radius: $border-radius-sm;

$multiselect-tag-bg-hover: $state-bg-hover;
$multiselect-tag-border-hover: $state-border-hover;
$multiselect-tag-color-hover: $state-color-hover;
