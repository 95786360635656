.navbar {
  width: 100%;
  display: flex;
  position: relative;
  background: linear-gradient(180deg, $primary 0%, $primary200 100%);
  box-shadow: none;
  z-index: index($z_layout, navbar);

  .middle {
    flex: 1;
    display: flex;
    justify-content: space-between;
    padding-right: $baseline_half;
  }

  .right {
    display: flex;
  }

  div {
    .list-unstyled {
      position: relative;
      margin: 0;
      padding: 0;
      list-style: none;
      height: $subnav-height;

      &.list-right {
        li.navbar__item {
          margin-left: $baseline_half;
          margin-bottom: 0;

          a {
            color: #cac1f9;

            i {
              opacity: 1;
            }
          }
        }
      }

      div.navbar__group-bubble {
        display: flex;
        position: absolute;
        top: $baseline * 0.6;
        right: $baseline * 0.6;
        align-items: center;
        justify-content: center;
        width: $baseline * 0.75;
        height: $baseline * 0.75;
        border-radius: 50%;
        background-color: $warning;
        color: $white;
        font-size: $base-font-size * 0.8;
        font-weight: bold;
        cursor: pointer;

        &::before {
          display: block;
          position: absolute;
          width: 19px;
          height: 19px;
          border-radius: 50%;
          box-shadow: 0 2px 0 rgba($dark, 0.6);
          content: '';
          z-index: 0;
        }
      }

      li {
        display: block;
        margin-bottom: 0;
        margin-left: $baseline * 0.8;
        float: left;
        line-height: $subnav-height * 0.5;

        &.navbar__group-item {
          position: relative;
          height: $subnav-height;
          margin-left: 0;
          padding-right: $baseline * 0.8;
          padding-left: $baseline * 0.8;
          background: $gray700;

          i {
            color: $light200;
            font-size: calc(#{$base-font-size} * 2);
            text-shadow: 0 2px $gray800;
            opacity: 1;
          }
        }
      }

      a,
      span {
        position: relative;
        width: 100%;
        color: $white;

        i {
          display: block;
          top: 0;
          left: 0;
          width: 100%;
          line-height: $subnav-height;
          text-align: center;
          text-shadow: 0 2px $primary500;
          opacity: 0.68;

          &.icon-bundle {
            top: -2px;
          }
        }

        // a:hover
        &:hover {
          opacity: 1;
        }
      }

      a {
        display: block;
      }
    }
  }

  .navbar__group-list {
    position: fixed;
    top: $subnav-height;
    min-width: 300px;
    max-height: calc(100vh - #{$subnav-height});
    background: $gray700;
    overflow: auto;
    z-index: index($z_layout, navbar);

    &.inactive {
      max-height: 0;
      transform: scaleY(0);

      ul {
        list-style-type: none;
        padding-top: $baseline;
        padding-bottom: $baseline;
      }
    }

    &.active {
      transform: scaleY(1);
      transform-origin: top;
      transition: transform 0.2s ease-in-out;

      ul {
        list-style-type: none;
        width: 100%;
        padding-top: $baseline;
        padding-bottom: $baseline;
        overflow: auto;
      }

      .footer-warning {
        position: sticky;
        bottom: 0;
        left: 3%;
        width: 93%;
        border-radius: 5px 5px 0 0;
        background: $teal500;
        color: $gray700;
        font-size: $base-font-size * 0.85;
        text-align: center;
        animation: openDrawer 300ms 50ms both;

        &.hide {
          animation: closeDrawer 300ms 50ms both;
        }

        > i {
          padding-left: 0.5rem;
          font-size: $base-font-size * 0.85;
        }
      }
    }

    li {
      position: relative;
      margin-bottom: $baseline;

      &:last-child {
        margin-bottom: 0;
      }

      &.navbar__group-list_disabled a {
        color: $gray500;
      }

      span {
        color: $white200;
      }

      i {
        margin-top: 2px;
        margin-right: $baseline_sm * 4;
        color: $light200;
        font-size: $base-font-size;

        &.icon-pause_circle_filled {
          float: right;
          color: $warning;
          font-size: $base-font-size * 1.25;

          &::before {
            display: block;
            position: absolute;
            top: 4px;
            right: 27px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: $white;
            box-shadow: 0 2px 0 rgba($dark300, 0.6);
            content: '';
            z-index: -1;
          }
        }
      }
    }
  }

  .global_search {
    position: relative;
    flex: 0 1 auto;
    width: 320px;
    background: none;

    input {
      top: $subnav-height * 0.2;
      height: $subnav-height * 0.6;
      padding: 0 $baseline_half 0 $baseline * 1.8;
      transition: background 250ms;
      border: 2px solid;
      border-color: transparent;
      border-radius: 30px;
      background: #492db8;
      font-family: $primaryFont;
      font-size: $base-font-size;
      box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.11);
      color: $white;

      &::placeholder {
        color: $white;
      }

      &:active {
        color: $white;
      }

      &:focus {
        background: rgba(33, 16, 81, 0.36);
        border-color: #cac1f9;
        color: $white;
      }

      textarea {
        color: $white;

        &:focus {
          color: $white;
        }
      }
    }

    button {
      position: absolute;
      top: $subnav-height * 0.2;
      left: $subnav-height * 0.1;
      width: $subnav-height * 0.6;
      height: $subnav-height * 0.6;
      padding: 0;
      outline: none;
      background: none;
      text-align: center;
      pointer-events: none;
      z-index: index($z_forms, inputs);

      i {
        color: $white;
      }
    }

    .material-icons {
      position: static;
      transition: color 250ms;
      text-align: center;

      &:hover {
        color: $primary;
      }
    }

    .active {
      top: 0;
      height: $subnav-height;
      line-height: 55px;

      input {
        color: $white;

        &:focus {
          color: $white;
        }
      }

      button {
        pointer-events: auto;
      }
    }
  }

  .user_menu {
    position: relative;
    flex: 0 1 auto;
    width: $subnav-height;
    background: none;

    .material-icons {
      position: static;
      width: $subnav-height;
      height: $subnav-height;
      transition: color 250ms;
      color: rgba(255, 255, 255, 0.68);
      line-height: $subnav-height;
      text-align: center;
      text-shadow: 0 2px $primary500;
    }

    .user_menu__dropdown {
      position: fixed;
      top: $subnav-height;
      right: 0;
      min-width: 160px;

      .no_feature_flags > a {
        opacity: 0.5;
        cursor: not-allowed;
      }

      > ul {
        padding: 0 $baseline 0 $baseline_half;
        transition: max-height 333ms ease-in-out;
        background-color: $secondary;
        text-align: right;
        list-style-type: none;
        opacity: 1;
        overflow: auto;

        li:first-of-type {
          padding-top: $baseline_half;
        }

        li:last-child {
          margin-bottom: 0;
          padding-bottom: $baseline_half;
        }

        li,
        a {
          transition: color 250ms;
          color: $white500;

          &:hover {
            color: $white;
          }
        }

        li {
          width: 100%;
        }
      }
    }

    div.inactive {
      max-height: 0;
      transition: max-height 333ms ease-in-out;
      overflow: hidden;

      ul {
        height: 0;
        transition: max-height 333ms ease-in-out;

        &::before {
          transition: border-color ease-in-out 100ms;
          border-bottom-color: $primary200;
        }
      }
    }

    div.active {
      height: auto;
      max-height: 100%;
      transition: max-height 333ms ease-in-out;
      overflow: hidden;

      > ul {
        height: 100%;
        max-height: 100%;
        transition: max-height 333ms ease-in-out;
        @include boxShadow($black);
        z-index: index($z_layout, userMenu);

        &::before {
          position: fixed;
          right: 22px;
          top: 42.5px;
          width: 0;
          height: 0;
          margin-left: -10px;
          border: solid transparent;
          border-width: 10px;
          border-bottom-color: $secondary;
          content: ' ';
          pointer-events: none;
        }
      }
    }
  }

  .navbar__item--settings {
    position: relative;

    .navbar__item--settings__pause-icon {
      position: absolute;
      top: -7px;
      left: 13px;
      border-radius: 50%;
      color: lighten(rgba($warning, 1), 0.09%);
      text-shadow: none;
      opacity: 1;
      z-index: 1;

      &::before {
        display: block;
        position: absolute;
        top: 22px;
        right: 3px;
        width: 19px;
        height: 19px;
        border-radius: 50%;
        background-color: $white;
        box-shadow: 0 2px 0 rgba($dark, 0.6);
        content: '';
        z-index: -1;
      }
    }
  }
}
