.spinner {
  $spinner-size: 24px;
  $stroke-width: 3px;

  &.big {
    $spinner-size: 36px;
    $stroke-width: 4px;
  }

  display: inline-block;
  width: $spinner-size;
  height: $spinner-size;

  .spinner-inner {
    width: $spinner-size;
    height: $spinner-size;
    animation: spin 2.5s linear infinite;
    border-radius: 300px;
    background: transparent;

    svg {
      width: $spinner-size;
      height: $spinner-size;
      stroke-dasharray: 86;
      stroke-dashoffset: 0;
      stroke: $white;
      animation: dash 1s ease-in-out infinite;
      animation-direction: alternate-reverse;
      stroke-width: $stroke-width;
      transform: rotate(180deg);
    }
  }

  &.dark {
    .spinner-inner {
      svg {
        stroke: $dark600;
      }
    }
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
