@import '../../../../scss/base/colors';
@import '../../../../scss/base/variables';

.drawerNav {
  display: flex;
  align-items: center;
  min-height: $subnav-height * 0.95;
  background-color: $secondary;
  color: $white;

  ul {
    width: 100%;
    padding: 0 $baseline * 0.6;
    list-style: none;

    li {
      margin: 0;
      background: none;
      font-size: 0.9rem;
      font-weight: normal;
      text-transform: none;

      &:hover {
        opacity: 1;
      }
    }
  }

  .suspicionScore {
    padding: $baseline_sm 0;
    float: right;
  }

  .tab {
    margin-right: $baseline_half;
    padding: $baseline_sm $baseline_half;
  }

  .activeTab {
    background-color: $white;
    color: $secondary;
  }
}
