@import '../../../scss/base/variables';
@import '../../../scss/base/colors';

.rwInput {
  color: $dark;
}

.values {
  padding: $baseline;

  :global(.expando) {
    display: flex;
    margin-top: $baseline_half;

    > div:first-child {
      margin-right: $baseline_sm;
    }
  }

  :global(.input-container) {
    background: white;
  }

  :global(.expando.date-range) {
    margin-left: 25px;

    > div {
      &:last-child {
        :global(.rw-popup.rw-calendar.rw-widget-container.rw-widget) {
          left: -5.6rem;
        }
      }
    }
  }

  > div {
    margin-bottom: $baseline_half;
  }
}

.icon {
  margin-right: $baseline_half;
  margin-bottom: 4px;
  font-size: $h2-font-size;
}

.subheader {
  margin-bottom: $baseline_sm;
}

.filterMargin {
  margin-left: 25px;
}
