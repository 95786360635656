@import '../../../../scss/base/variables';

.header {
  display: flex;
  align-items: center;
}

.content {
  flex: 1;
}

.byline {
  margin-bottom: 0;
  font-size: $base-font-size * 0.9;
  opacity: 0.6;
}

.actions {
  flex: 0 1 auto;
  padding-right: $baseline_half;
}

.action {
  display: inline;
  margin-left: $baseline_half;
}

@media print {
  .actions {
    display: none;
  }
}
