@import '../../../scss/base/variables';
@import '../../../scss/base/colors';

.wrapper {
  display: flex;
  margin-bottom: $baseline_half;
}

.headerWrapper {
  margin: auto;

  .text {
    margin: 0 $baseline_half;
    line-height: normal;

    &.label {
      color: $gray650;
      font-size: $xSmall;
    }

    &.headline {
      color: $dark;
      font-size: $base-font-size;
      .light {
        color: $gray500;
      }
    }
  }
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $entity-icon-wrapper;
  height: $entity-icon-wrapper;
  border-radius: 100%;

  &.secondary {
    background: $secondary1000;
  }

  &.teal {
    background: $teal500;
  }
}

.icon {
  color: $white;
}
